import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { Subject } from 'rxjs';
function Terminal_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.welcomeMessage);
  }
}
function Terminal_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "span", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 9);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 10);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const command_r4 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.prompt);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(command_r4.text);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-live", "polite");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(command_r4.response);
  }
}
class TerminalService {
  commandSource = new Subject();
  responseSource = new Subject();
  commandHandler = this.commandSource.asObservable();
  responseHandler = this.responseSource.asObservable();
  sendCommand(command) {
    if (command) {
      this.commandSource.next(command);
    }
  }
  sendResponse(response) {
    if (response) {
      this.responseSource.next(response);
    }
  }
  static ɵfac = function TerminalService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TerminalService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TerminalService,
    factory: TerminalService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TerminalService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Terminal is a text based user interface.
 * @group Components
 */
class Terminal {
  el;
  terminalService;
  cd;
  /**
   * Initial text to display on terminal.
   * @group Props
   */
  welcomeMessage;
  /**
   * Prompt text for each command.
   * @group Props
   */
  prompt;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  commands = [];
  command;
  container;
  commandProcessed;
  subscription;
  constructor(el, terminalService, cd) {
    this.el = el;
    this.terminalService = terminalService;
    this.cd = cd;
    this.subscription = terminalService.responseHandler.subscribe(response => {
      this.commands[this.commands.length - 1].response = response;
      this.commandProcessed = true;
    });
  }
  ngAfterViewInit() {
    this.container = DomHandler.find(this.el.nativeElement, '.p-terminal')[0];
  }
  ngAfterViewChecked() {
    if (this.commandProcessed) {
      this.container.scrollTop = this.container.scrollHeight;
      this.commandProcessed = false;
    }
  }
  set response(value) {
    if (value) {
      this.commands[this.commands.length - 1].response = value;
      this.commandProcessed = true;
    }
  }
  handleCommand(event) {
    if (event.keyCode == 13) {
      this.commands.push({
        text: this.command
      });
      this.terminalService.sendCommand(this.command);
      this.command = '';
    }
  }
  focus(element) {
    element.focus();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = function Terminal_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Terminal)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(TerminalService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Terminal,
    selectors: [["p-terminal"]],
    hostAttrs: [1, "p-element"],
    inputs: {
      welcomeMessage: "welcomeMessage",
      prompt: "prompt",
      style: "style",
      styleClass: "styleClass",
      response: "response"
    },
    decls: 9,
    vars: 8,
    consts: [["in", ""], [3, "click", "ngClass", "ngStyle"], [4, "ngIf"], [1, "p-terminal-content"], [4, "ngFor", "ngForOf"], [1, "p-terminal-prompt-container"], [1, "p-terminal-content-prompt"], ["type", "text", "autocomplete", "off", "autofocus", "", 1, "p-terminal-input", 3, "ngModelChange", "keydown", "ngModel"], [1, "p-terminal-prompt"], [1, "p-terminal-command"], [1, "p-terminal-response"]],
    template: function Terminal_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 1);
        i0.ɵɵlistener("click", function Terminal_Template_div_click_0_listener() {
          i0.ɵɵrestoreView(_r1);
          const in_r2 = i0.ɵɵreference(8);
          return i0.ɵɵresetView(ctx.focus(in_r2));
        });
        i0.ɵɵtemplate(1, Terminal_div_1_Template, 2, 1, "div", 2);
        i0.ɵɵelementStart(2, "div", 3);
        i0.ɵɵtemplate(3, Terminal_div_3_Template, 7, 4, "div", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 5)(5, "span", 6);
        i0.ɵɵtext(6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "input", 7, 0);
        i0.ɵɵtwoWayListener("ngModelChange", function Terminal_Template_input_ngModelChange_7_listener($event) {
          i0.ɵɵrestoreView(_r1);
          i0.ɵɵtwoWayBindingSet(ctx.command, $event) || (ctx.command = $event);
          return i0.ɵɵresetView($event);
        });
        i0.ɵɵlistener("keydown", function Terminal_Template_input_keydown_7_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.handleCommand($event));
        });
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", "p-terminal p-component")("ngStyle", ctx.style);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.welcomeMessage);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.commands);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.prompt);
        i0.ɵɵadvance();
        i0.ɵɵtwoWayProperty("ngModel", ctx.command);
      }
    },
    dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgStyle, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel],
    styles: ["@layer primeng{.p-terminal{height:18rem;overflow:auto}.p-terminal-prompt-container{display:flex;align-items:center}.p-terminal-input{flex:1 1 auto;border:0 none;background-color:transparent;color:inherit;padding:0;outline:0 none}.p-terminal-input::-ms-clear{display:none}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Terminal, [{
    type: Component,
    args: [{
      selector: 'p-terminal',
      template: `
        <div [ngClass]="'p-terminal p-component'" [ngStyle]="style" [class]="styleClass" (click)="focus(in)">
            <div *ngIf="welcomeMessage">{{ welcomeMessage }}</div>
            <div class="p-terminal-content">
                <div *ngFor="let command of commands">
                    <span class="p-terminal-prompt">{{ prompt }}</span>
                    <span class="p-terminal-command">{{ command.text }}</span>
                    <div class="p-terminal-response" [attr.aria-live]="'polite'">{{ command.response }}</div>
                </div>
            </div>
            <div class="p-terminal-prompt-container">
                <span class="p-terminal-content-prompt">{{ prompt }}</span>
                <input #in type="text" [(ngModel)]="command" class="p-terminal-input" autocomplete="off" (keydown)="handleCommand($event)" autofocus />
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-terminal{height:18rem;overflow:auto}.p-terminal-prompt-container{display:flex;align-items:center}.p-terminal-input{flex:1 1 auto;border:0 none;background-color:transparent;color:inherit;padding:0;outline:0 none}.p-terminal-input::-ms-clear{display:none}}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: TerminalService
  }, {
    type: i0.ChangeDetectorRef
  }], {
    welcomeMessage: [{
      type: Input
    }],
    prompt: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    response: [{
      type: Input
    }]
  });
})();
class TerminalModule {
  static ɵfac = function TerminalModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TerminalModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TerminalModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, FormsModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TerminalModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule],
      exports: [Terminal],
      declarations: [Terminal]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Terminal, TerminalModule, TerminalService };
